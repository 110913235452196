import 'reflect-metadata';

const TIMESTAMP = Date.now();

import(/* webpackChunkName: "core" */ '@/app/core')
  .then(({ bootstrap }) => bootstrap('#app'))
  .catch(() => void 0) //TODO prevent unhandled error toastr
  .finally(() => {
    const splash: HTMLElement | null = document.querySelector('#splash');

    if (!splash) {
      return;
    }

    splash.addEventListener('transitionend', () => {
      const parent: HTMLElement | null = splash.parentElement;
      parent && parent.removeChild(splash);
    });

    setTimeout(() => splash.classList.add('hide'), Math.max(500, Date.now() - TIMESTAMP));
  });
